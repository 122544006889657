<template>
  <div class="xy-alert-box">
    <div class="xy-alert-content">
      <div class="xy-alert-body">
        <i class="el-icon-circle-close" @click="alertClose"></i>
        <div class="xy-alert-title">{{ title }}</div>
        <div v-if="imgUrl.length > 0" class="xy-alert-url">
          <div v-for="item in imgUrl" :key="item.title">
            <img :src="item.imgUrl" alt="" />
          </div>
        </div>
        <div class="xy-alert-btns tc">
          <el-button
            class="mb"
            type="primary"
            v-for="item in btns"
            :key="item.id"
            @click="handClick(item.url, item.IsLink)"
            :size="clientWidth > 768 ? 'medium' : 'mini'"
          >
            {{ item.title }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'xyAlertBox',
  data () {
    return {}
  },
  props: {
    title: {
      default: '',
    },
    imgUrl: {
      default: []
    },
    btns:{
      default: ()=> {
        return []
      }
    }
  },
  methods: {
    alertClose() {
      this.$emit('alertClose')
    },
    // !按钮点击
    handClick (url, Link) {
      if(Link) {
        window.location.href = url
      } else {
        this.$router.push(url)
      }
    }
  },
  computed: {
    ...mapState(['clientWidth'])
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss';
</style>