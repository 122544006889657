<template>
    <div class="fence">
        <div class="fence-box">
            <!-- 面包屑组件 -->
            <div class="crumb">
                <breadcrumb :refer="refer"></breadcrumb>
            </div>

            <div class="fence-box0" :class="!isNewPage ? 'is-newpage' : ''">
                <div class="fence-nav" v-if="isNewPage">
                    <h1>习剑</h1>
                    <div class="fence-menu">
                        <div v-for="(item, index) in Modules" :key="index" class="Fence-nav"
                            :class="'Fence-nav' + index">
                            <h1 class="nav-type flex3">
                                <b>{{ item.navName }}</b> <i class="el-icon-caret-bottom"></i>
                            </h1>
                            <div class="nav-list">
                                <ul>
                                    <li v-for="itemChild in item.navList" :key="itemChild.value"
                                        @click="navTabs(itemChild.value, index)" class="flex0 cur">
                                        {{ itemChild.text }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div v-if="Infos.isPartner">
                            <div v-for="item in directionType" :key="item.navName" class="Fence-nav"
                                :class="'Fence-nav3'">
                                <h1 class="nav-type flex3">
                                    <b>{{ item.navName }}</b> <i class="el-icon-caret-bottom"></i>
                                </h1>
                                <div class="nav-list">
                                    <ul>
                                        <li v-for="itemChild in item.navList" :key="itemChild.value"
                                            @click="navTabs(itemChild.value, 2)" class="flex0 cur">
                                            {{ itemChild.text }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fence-detail">
                    <div v-if="detailList.videoUrl && !iscontentUrl" class="fence-video">
                        <div v-if="detailList.videoUrl === ''" class="no-video"></div>
                        <div class="videos" v-else @contextmenu.stop="$event.returnValue = false">
                            <div v-loading="loading"></div>
                            <div class="play-shade shade0" v-if="isVideoVisible">
                                <div class="play-icon cur" @click="watchVideo"></div>
                            </div>
                            <video-player v-if="isPlayShade" @play="onPlayerPlay($event)" @pause="onPlayerPause($event)"
                                @timeupdate="onPlayerTimeupdate($event)" @statechanged="playerStateChanged($event)"
                                class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                                :options="playerOption"></video-player>
                        </div>
                        <div class="videoList flex7">
                            <div class="videoList-item cur" v-for="(item, index) in videoList"
                                @click="changeVideo(item.videoUrl, index)" :key="index"
                                :class="index == activeIndex ? 'video-item-active' : ''">
                                {{ item.videoName }}
                            </div>
                        </div>
                    </div>
                    <div class="fence-detail-text">
                        <div v-if="iscontentUrl">
                            <iframe :src="contentUrl" frameborder="0"></iframe>
                            <div class="videoList flex7" v-if="contentList.length > 1">
                                <div class="videoList-item content-item cur" v-for="(item, index) in contentList"
                                    @click="
                    changeContent(item.contentUrl, item.contentName, index)
                    " :key="index" :class="index == activeIndex1 ? 'video-item-active' : ''">
                                    {{ item.contentName }}
                                </div>
                            </div>
                        </div>
                        <h1>{{ detailList.title }}</h1>
                        <div class="fence-date">
                            <span class="">{{ detailList.releaseTimeStr }}</span>
                            <span v-if="detailList.videoUrl && detailList.videoClickNum > 10000" class="play-num">
                                {{ (detailList.videoClickNum / 10000).toFixed(1) }}w次播放
                            </span>
                            <span v-if="detailList.videoUrl && detailList.videoClickNum < 10000" class="play-num">
                                {{ detailList.videoClickNum }}次播放
                            </span>
                        </div>
                        <div v-if="!iscontentUrl">
                            <div v-if="detailList.pdfFileName" class="lookPdf">
                                <div class="pdf0">
                                    <img src="@/assets/PC/fenceDetail/pdf-icon.png" />
                                    <span class="pdf-name">{{ detailList.pdfFileName }}</span>
                                </div>
                                <div class="operation">
                                    <div v-if="token">
                                        <span class="link" @click="onlinePdf">在线观看</span>
                                        <!-- | <span class="link" @click="downPdf">下载阅读</span> -->
                                    </div>
                                    <div v-else style="color: #738294">
                                        <span @click="goLogin" class="link">登录</span>后可在线观看或下载阅读
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="fence-introduction">{{ detailList.introduction }}</p>
                        <div class="fence-detail-text-item0" v-html="detailList.context"></div>
                        <div class="Tag flex4">
                            <div v-for="(item, index) in detailList.tags" :key="index" class="tag-item">
                                {{ item }}
                            </div>
                        </div>
                        <div class="fenceOperation" v-if="detailList">
                            <div class="Operation-item praise" :class="[isPraise ? 'praiseActive' : '']" @click="praise"
                                title="点赞">
                                <img src="@/assets/PC/fenceDetail/praise.png" />
                                <img src="@/assets/PC/fenceDetail/praise1.png" alt="" />
                                <span v-if="detailList.praiseNum < 10000">
                                    {{ detailList.praiseNum }}
                                </span>
                                <span v-else>
                                    {{ (detailList.praiseNum / 10000).toFixed(1) }}w
                                </span>
                            </div>
                            <div class="Operation-item collect" :class="[isCollect ? 'collectActive' : '']"
                                @click="collect" title="收藏">
                                <img src="@/assets/PC/fenceDetail/collect.png" />
                                <img src="@/assets/PC/fenceDetail/collect1.png" alt="" />
                                <span v-if="detailList.collectionNum < 10000">
                                    {{ detailList.collectionNum }}
                                </span>
                                <span v-else>
                                    {{ (detailList.collectionNum / 10000).toFixed(1) }}w
                                </span>
                            </div>
                            <div class="Operation-item share" ref="seatTipOperation" title="转发">
                                <img src="@/assets/PC/fenceDetail/share.png" alt="" />
                                <span v-if="detailList.sharedNum < 10000">
                                    {{ detailList.sharedNum }}
                                </span>
                                <span v-else>{{ (detailList.sharedNum / 10000).toFixed(1) }}w</span>
                                <div class="shareCode">
                                    <p>
                                        <img src="@/assets/PC/forum/icon_copy.png" width="20" height="20" />
                                        <span v-clipboard:copy="local" v-clipboard:success="onCopy">复制链接</span>
                                    </p>
                                    <p @click="weibo">
                                        <img src="@/assets/PC/forum/icon_sina.png" width="20" height="19" alt="" />
                                        <span>新浪微博</span>
                                    </p>
                                    <p v-show="clientWidth > 750">
                                        <img src="@/assets/PC/forum/icon_wechat.png" alt="" />
                                        <span>微信扫一扫</span>
                                    </p>
                                    <div v-show="clientWidth > 750" id="qrCode" ref="qrCodeDiv"></div>
                                    <div class="san0"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <xy-alert-box v-show="AlertData.state" :title="AlertData.title" :imgUrl="AlertData.imgUrl"
            :btns="AlertData.btns" @alertClose="alertClose">
        </xy-alert-box>
        <!-- 登录执行弹窗 -->
        <shadeAlert :shadeConfig="shadeConfig" @closeAlert="closeAlertChange" />

        <div class="LimitAlert">
            <div v-if="isLimit" class="shade flex0">
                <div class="shade-section tc">
                    <div class="shade-section-title">
                        {{ limitText }}
                    </div>
                    <div class="shade-section-btn">
                        <button type="button" class="xy-btn" @click="goIndex">
                            返回首页
                        </button>
                    </div>
                    <i class="el-icon-circle-close close-alert co-fff cur" @click="goIndex"></i>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>
import breadcrumb from "@/components/Breadcrumb/index.vue";
import xyAlertBox from "@/components/xyAlertBox";
import { debounce, GetShareData, GetWxShares } from "@/utils/common";
import QRCode from "qrcodejs2";
import * as Fence from "@/api/library.js";
import shadeAlert from "@/components/shadeAlert/index.vue";

import { mapState, mapGetters } from "vuex";

export default {
    name: "fence",
    data() {
        return {
            // !弹窗配置
            shadeConfig: {},
            // !产品、类型筛选
            Modules: [],
            // !方向筛选
            directionType: [],
            navIds: [0, 0, 0], // !左侧导航选中id
            loading: false, // !视频播放loading
            numPages: 1, // !PDF页数
            isVideoVisible: false,
            isPlay: false,
            isPlayShade: false,
            isPraise: false,
            isCollect: false,
            refer: [
                { name: "首页", href: "/" },
                { name: "习剑", href: "/Home/Fence/FenceIndex" },
            ],
            detailList: "",
            videoUrl: "", // !默认视频 Url
            videoList: [], // !视频列表
            contentList: [], // !iframe内容列表
            activeIndex: 0, // !当前选中视频
            activeIndex1: 0, // !当前选中内容
            playerOption: {
                sources: [{ type: "video/mp4", src: "" }],
                muted: true,
                preload: "auto",
                aspectRatio: "16:9",
                language: "zh-CN",
                playbackRates: [1.0, 1.5, 2.0],
                controls: true,
                notSupportedMessage: "",
            },
            local: window.location.href,
            nowPlayer: 0, // !记录播放时长
            PlayerStatus: 0, // !播放进度
            isPlayer: true, // !记录播放时长状态
            LoginState: false, // !登录状态弹窗
            duration: 0, //视频时长
            // ! 视频播放器参数
            video: {
                // @ 播放倍速
                rate: 1,
                // @ 定时器秒数
                setTime: 0,
                // @ 观看时长
                watchTime: 0,
            },
            // ! 徽章弹窗
            AlertData: {
                state: false,
                title: "",
                imgUrl: [],
                btns: [
                    {
                        id: 1,
                        url: "/Home/Profile/badge",
                        title: "查看勋章",
                        IsLink: false,
                    },
                ],
            },
            iscontentUrl: false,
            contentUrl: "",
            isLimit: false,
            limitText: "",
            settimer: "",

            isNewPage: true,
        };
    },
    mounted() {
        const query = this.$route.query;
        if (!query.id) {
            this.$toast("Url参数错误，正在返回首页...");
            setTimeout(() => {
                this.$router.push("/Home/Fence/FenceIndex");
            }, 500);
            return false;
        }

        if (query.isnewpartner) {
            // this.$route.meta.activePath = "/Home/NewPartner"
            this.isNewPage = false
            this.refer[1].href = "/Home/NewPartner"
            this.refer[1].name = "入门指南"
        } else {
            // this.$route.meta.activePath = "/Home/Fence/FenceIndex"
            this.isNewPage = true
        }
        this.GetInit();
        // GetShareData(1, "E3DVideoDetail", this.$route.query.id);
    },
    computed: {
        ...mapState(["clientWidth", "Infos", "token"]),
        ...mapGetters(["module"]),
        player() {
            return this.$refs.videoPlayer.player;
        },
    },
    methods: {
        // !关闭登录指引弹窗
        closeAlertChange(state) {
            this.shadeConfig.show = state;
        },
        goIndex() {
            this.isLimit = false;
            this.$router.push("/Home/Index");
        },
        // ! 初始化视频播放
        GetInit() {
            const id = { id: this.$route.query.id };
            Fence.GetDetails(id).then((res) => {
                if (res.status == -100) {
                    if (!this.$store.state.token) {
                        // 取消登录限制
                        this.shadeConfig = {
                            show: true, // 展示隐藏弹窗
                            closeIcon: true, // 是否需要关闭按钮
                            title: "您还没有登录，<br/>请登录后再来学习吧！",
                            text: "", // 弹窗内容
                            btnType: "link", // 按钮形式
                            btnList: [
                                {
                                    text: "已有剑盟账号，前往登录",
                                    link: "/Login?redirectUrl=" + this.$route.fullPath,
                                },
                                {
                                    text: "没有账号，立即注册",
                                    link: "/Register?redirectUrl=" + this.$route.fullPath,
                                },
                            ],
                        };
                    } else {
                        this.limitText = res.message;
                        this.isLimit = true;
                    }
                    return false;
                }
                this.detailList = res.data;
                const { isPraise, isCollect, productIds } = res.data;
                this.isPraise = isPraise;
                this.isCollect = isCollect;
                this.navActiveId = productIds;

                if (this.isNewPage) {
                    this.refer.push({ name: this.detailList.title });
                    this.refer[1].href = "/Home/Fence/FenceIndex?id=" + this.navActiveId;
                    if (res.data.contentUrl) {
                        GetShareData(1, "MEDDPICC", this.$route.query.id);
                    } else {
                        GetShareData(1, "E3DVideoDetail", this.$route.query.id);
                    }
                } else {
                    this.refer.push({ name: this.detailList.title });
                    GetShareData(1, "introducLearn", this.$route.query.id);
                }

                // 视频展示
                if (res.data.videoUrl) {
                    const videos = res.data.videoUrl.split(",");
                    this.videoUrl = videos[0];
                    videos.filter((item, index) => {
                        this.videoList.push({
                            videoUrl: item,
                            videoName: "视频-" + (index * 1 + 1),
                        });
                    });
                }
                !res.data.pdfFileUrl && !res.data.videoUrl && !res.data.contentUrl
                    ? this.learningState()
                    : "";

                if (res.data.contentUrl && res.data.contentUrl.length > 0) {
                    const contents = res.data.contentUrl.split(",");
                    this.contentUrl = contents[0].split("?")[0];
                    this.iscontentUrl = true;
                    contents.filter((item, index) => {
                        this.contentList.push({
                            contentUrl: item.split("?")[0] ? item.split("?")[0] : "",
                            contentName: item.split("?")[1] ? item.split("?")[1] : "",
                        });
                    });
                    if (res.data.groupInfo.length == 0 || contents.length == 1) {
                        this.settimer = setTimeout(() => {
                            this.SalesSkillLearn(res.data.id);
                            this.AddIntegralsss(res.data.id, 4, true);
                        }, 300000)
                    } else {
                        var firstlink = contents[0];
                        res.data.groupInfo.filter((item) => {
                            if (item.contentUrl == firstlink) {
                                this.settimer = setTimeout(() => {
                                    this.SalesSkillLearn(item.id);
                                    this.AddIntegralsss(item.id, 4, true);
                                }, 300000)
                            }
                        });
                    }
                }

                this.$nextTick(() => {
                    this.bindQRCode();
                    if (res.data.videoUrl) {
                        const Video = document.querySelector(".vjs-playback-rate-value");
                        Video.addEventListener("DOMNodeInserted", (e) => {
                            this.video.rate = e.target.data.split("x")[0] * 1;
                        });
                    }
                });
                const share = {
                    title: res.data.title,
                    // desc: res.data.introduction,
                    desc: '',
                };

                GetWxShares(share);
            });
        },
        // ! 点击左侧nav产品
        navTabs(id, index) {
            this.navIds[index] = id;
            let routeAdd = "/Home/Fence/FenceIndex?";
            const navText = ["Id", "typesId", "directionsId"];
            this.navIds.filter((v, i) => {
                routeAdd += navText[i] + "=" + v + "&";
                return routeAdd;
            });
            this.$router.push(routeAdd);
        },
        // ! 视频显示
        watchVideo() {
            this.isVideoVisible = !this.isVideoVisible;
            if (this.isPlay) {
                this.player.pause();
            } else {
                this.player.play();
            }
            this.EventLogs(1);
        },
        // ! 视频切换
        changeVideo(url, index) {
            if (this.activeIndex != index) {
                this.activeIndex = index;
                this.videoUrl = url;
                this.playerOption.autoplay = true;
                this.loading = true;
                const shade = document.querySelector(".shade0");
                if (shade) {
                    shade.remove();
                }
            }
        },
        // 切换内容
        changeContent(url, name, index) {
            if (this.activeIndex1 != index) {
                clearTimeout(this.settimer)
                this.activeIndex1 = index;
                this.contentUrl = url;

                var link = url + "?" + name;
                this.detailList.groupInfo.filter((item) => {
                    if (item.contentUrl == link) {
                        this.settimer = setTimeout(() => {
                            this.SalesSkillLearn(item.id);
                            this.AddIntegralsss(item.id, 4, true);
                        }, 300000)
                    }
                });
            }
        },
        // ! 开始播放
        onPlayerPlay(player) {
                        // 取消登录限制

            // if (!this.$store.state.token) {
            //     this.player.pause();
            //     this.shadeConfig = {
            //         show: true, // 展示隐藏弹窗
            //         closeIcon: true, // 是否需要关闭按钮
            //         title: "您还没有登录，<br/>请登录后再来学习吧！",
            //         text: "", // 弹窗内容
            //         btnType: "link", // 按钮形式
            //         btnList: [
            //             {
            //                 text: "已有剑盟账号，前往登录",
            //                 link: "/Login?redirectUrl=" + this.$route.fullPath,
            //             },
            //             {
            //                 text: "没有账号，立即注册",
            //                 link: "/Register?redirectUrl=" + this.$route.fullPath,
            //             },
            //         ],
            //     };
            //     return false;
            // }
            this.duration = Math.floor(player.duration())
            this.loading = false;
            this.isVideoVisible = false;
            this.nowPlayer = 0;
        },
        // ! 暂停播放
        onPlayerPause(player) {
            this.nowPlayer = 0;
        },
        // ! 当前播放位置发生变化时触发。
        onPlayerTimeupdate(player) {
            if (Math.floor(player.currentTime()) > this.PlayerStatus) {
                this.PlayerStatus = Math.floor(player.currentTime());
                this.nowPlayer++;
            }
        },
        // ! 预览PDF
        onlinePdf() {
            this.pdfLearningState();
            if (!this.isNewPage) {
                this.AddIntegrals(3, "下载成功！");
            }
            if (document.documentElement.clientWidth > 750) {
                window.open(this.detailList.pdfFileUrl);
            } else {
                window.location.href = this.detailList.pdfFileUrl;
            }
        },
        // ! 下载PDF
        downPdf() {
            let u = navigator.userAgent;
            let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            this.AddIntegrals(3, "下载成功！");
            const clientWidth = document.documentElement.clientWidth;
            if (clientWidth <= 750 && !isIOS) {
                window.location.href = this.detailList.pdfFileUrl;
            } else if (clientWidth > 750) {
                let url = this.detailList.pdfFileUrl;
                let name = this.detailList.title + ".pdf";
                // 发送http请求，将文件链接转换成文件流
                fileAjax(
                    url,
                    function (xhr) {
                        downloadFile(xhr.response, name);
                    },
                    {
                        responseType: "blob",
                    }
                );
            } else if (isIOS) {
                window.location.href = this.detailList.pdfFileUrl;
            }

            function fileAjax(url, callback, options) {
                let xhr = new XMLHttpRequest();
                xhr.open("get", url, true);
                if (options.responseType) {
                    xhr.responseType = options.responseType;
                }
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        callback(xhr);
                    }
                };
                xhr.send();
            }

            function downloadFile(content, filename) {
                window.URL = window.URL || window.webkitURL;
                let a = document.createElement("a");
                let blob = new Blob([content]);
                // 通过二进制文件创建url
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = filename;
                a.click();
                // 销毁创建的url
                window.URL.revokeObjectURL(url);
            }
        },
        // ! 点赞
        praise() {
            if (!this.$store.state.token) {
                this.$toast("请登录后操作!");
                return false;
            }
            debounce(() => {
                const data = { resourcesId: this.$route.query.id, type: 0 };
                if (this.isPraise) {
                    Fence.UnPraise(data).then((res) => {
                        this.$toast(res.message);
                        if (res.status == 1) {
                            this.detailList.praiseNum -= 1;
                            this.isPraise = false;
                        }
                    });
                } else {
                    Fence.Praise(data).then((res) => {
                        this.$toast(res.message);
                        if (res.status == 1) {
                            this.detailList.praiseNum += 1;
                            this.isPraise = true;
                        }
                    });
                }
            }, 1000);
        },
        // ! 收藏
        collect() {
            if (!this.$store.state.token) {
                this.$toast("请登录后操作!");
                return false;
            }
            debounce(() => {
                const data = { libraryId: this.$route.query.id };
                if (this.isCollect) {
                    Fence.UnCollect(data).then((res) => {
                        this.$toast(res.message);
                        if (res.status == 1) {
                            this.detailList.collectionNum -= 1;
                            this.isCollect = false;
                        }
                    });
                } else {
                    Fence.Collect(data).then((res) => {
                        this.$toast(res.message);
                        if (res.status == 1) {
                            this.detailList.collectionNum += 1;
                            this.isCollect = true;
                        }
                    });
                }
            }, 1000);
        },
        // ! 复制链接
        onCopy() {
            this.$toast({ message: "复制成功!", duration: 1000 });
            this.EventLogs(2);
        },
        // ! 分享微博
        weibo() {
            this.AddIntegrals(2);
            this.EventLogs(2);
            var title = this.detailList.title;
            var url = window.location.href;
            url = encodeURIComponent(url);
            var a = screen.width / 2 - 300,
                d = screen.height / 2 - 175;
            window.open(
                "http://v.t.sina.com.cn/share/share.php?title=" + title + "&url=" + url,
                "Ignite",
                "status=0,toolbar=0,location=0,menubar=0,directories=0,resizable=1,scrollbars=0,width=800,height=530,top=" +
                d +
                ",left=" +
                a
            );
        },
        // ! 二维码生成
        bindQRCode: function () {
            new QRCode(this.$refs.qrCodeDiv, {
                text: window.location.href + "&code=" + Math.random(),
                width: 110,
                height: 110,
                correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
        },
        // ! 播放视频、分享资源
        EventLogs(type) {
            const log = {
                resourcesId: this.detailList.id,
                eventType: type,
            };
            Fence.AddEventLogs(log).then((res) => { });
        },
        // ! 增加积分、金币
        AddIntegrals(type) {
            const data = { resourcesId: this.$route.query.id, integralType: type };
            Fence.AddIntegral(data).then((res) => {
                if (res.status == 1) {
                    if (res.message > 0) {
                        this.$toast("+" + res.message + " 积分");
                    }
                } else {
                    this.$toast(res.message);
                }
            });
        },
        // ! 增加积分、金币
        AddIntegralsss(id, type, flag) {
            const data = { resourcesId: id, integralType: type };
            Fence.AddIntegral(data).then((res) => {
                if (res.status == 1) {
                    if (res.message > 0) {
                        this.$toast("+" + res.message + " 积分");
                    }
                    if (flag) {
                        this.AddIntegralsss(id, 5, false);
                    }
                } else {
                    this.$toast(res.message);
                }
            });
        },
        // ! 去登录
        goLogin() {
            this.LoginState = true;
            this.$router.push("/Login?redirectUrl=" + this.$route.fullPath);
        },
        // ! 习剑学习-阅读文章
        learningState() {
            if (this.token) {
                const Id = JSON.stringify(this.$route.query.id);
                Fence.DetailsLearn(Id).then((res) => {
                    if (res.status === 1) {
                        this.alertBox(res.data);
                    }
                });
            }
        },
        // ! 习剑学习-课程学习
        SalesSkillLearn(Id) {
            if (this.token) {
                const Ids = JSON.stringify(Id);
                Fence.SalesSkillLearn(Ids).then((res) => {
                    if (res.status === 1) {
                        this.alertBox(res.data);
                    }
                });
            }
        },
        // ! 习剑学习-pdf阅读
        pdfLearningState() {
            const Id = JSON.stringify(this.$route.query.id);
            Fence.PDFLearn(Id).then((res) => {
                if (res.status === 1) {
                    this.alertBox(res.data);
                }
            });
        },
        // ! 习剑学习-视频模式
        videoLearningState() {
            const data = JSON.stringify({
                id: this.$route.query.id,
                watchTime: this.video.watchTime,
            });
            if (this.token) {
                Fence.VideoLearning(data).then((res) => {
                    if (res.status === 1) {
                        this.alertBox(res.data);
                    }
                });
            }
        },
        // ! 播放状态监听
        playerStateChanged(e) {
            if (e.play || e.playing) {
                if (!this.timer) {
                    this.setTime();
                }
            } else if (e.waiting || e.pause || e.loadeddata) {
                clearInterval(this.timer);
                this.timer = null;
            }
        },
        // ! 计时器
        setTime() {
            this.timer = setInterval(() => {
                this.video.setTime++;
                // this.video.watchTime = this.video.watchTime + 1 * this.video.rate;
                this.video.watchTime = 5;
                if (this.video.setTime % 5 === 0) {
                    this.videoLearningState();
                }
            }, 1000);
        },
        // ! 获取勋章弹窗
        alertBox(imgUrl) {
            if (imgUrl.length > 0 && imgUrl[0].imgUrl) {
                // this.AlertData.title = '恭喜您获得“' +  title + '”成就勋章！'
                this.AlertData.title = "恭喜您获得新的成就勋章！";
                this.AlertData.imgUrl = imgUrl;
                this.AlertData.state = true;
            }
        },
        // ! 关闭弹窗
        alertClose() {
            this.AlertData.state = false;
        },
    },
    watch: {
        // !产品、类型、方向  筛选
        "$store.getters.module": {
            immediate: true,
            handler(module) {
                this.Modules = [
                    { navName: "产品与方向", navList: module.products },
                    { navName: "类型", navList: module.types },
                ];
                // this.directionType = [ {navName: '方向', navList: module.direction } ]
            },
        },
        videoUrl: function (news) {
            this.playerOption.sources[0].src = news;
            this.isPlayShade = true;
            this.isVideoVisible = true;
        },
        // !监听连续观看5分钟
        nowPlayer: function (news) {
            if (news == 0) {
                this.isPlayer = true;
            }
            if (this.isPlayer) {
                if (this.isNewPage) {
                    if (news == 300) {
                        this.isPlayer = false;
                        this.AddIntegrals(1);
                    }
                } else {
                    if (this.duration > 300) {
                        if (news == 300) {
                            this.isPlayer = false;
                            this.AddIntegrals(1);
                        }
                    } else {
                        if (news >= this.duration) {
                            this.isPlayer = false;
                            this.AddIntegrals(1);
                        }
                    }
                }
            }
        },
    },
    components: {
        shadeAlert,
        breadcrumb,
        xyAlertBox,
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
    @return ($true / $mobil_base) * 100vw;
}

.is-newpage {
    justify-content: center;
}

.fence {
    .FenceIndex-nav {
        width: 150px;
        height: auto;
        margin-right: 50px;
    }

    .Fence-nav {
        background: #f8f8f8;
        margin-bottom: 10px;
    }

    .Fence-nav .el-icon-caret-bottom,
    .Fence-nav .el-icon-caret-top {
        color: #ccc;
    }

    .nav-type {
        height: 44px;
        border-bottom: 2px #ccc solid;
        font-size: 16px;
        padding: 0 25px;
    }

    .nav-list ul {
        padding: 10px 0;
    }

    .nav-list ul li {
        min-height: 45px;
        font-size: 16px;
        transition: all 0.5s;
    }

    .nav-list ul li:hover {
        font-weight: bold;
        color: #6b18a8;
        background: #edcdf1;
    }

    .videoList {
        width: 100%;
        margin-top: 15px;
    }

    .videoList-item {
        margin-right: 20px;
        padding: 5px;
        color: #6b18a8;
        font-size: 16px;
    }

    .video-item-active {
        text-decoration: underline;
        font-weight: bold;
    }
}

.fence-box {
    width: 1200px;
    margin: 0 auto;
}

.fence-box0 {
    display: flex;
    height: auto;
}

.fence-nav {
    padding-right: 40px;
    width: 190px;
    min-width: 190px;
    overflow: hidden;
    font-size: 16px;
}

.fence-nav h1 {
    font-size: 24px;
    line-height: 50px;
}

.fence-detail {
    max-width: 1010px;
    width: 100%;
}

.fence-detail-text h1 {
    font-size: 20px;
    line-height: 1.5;
}

.fence-video {
    padding-bottom: 30px;
    width: 100%;
}

.fence-video img {
    width: 100%;
}

.fence-date {
    line-height: 2;
    color: #738294;
}

.fence-introduction {
    color: #738294;
    margin-bottom: 20px;
}

.play-num {
    margin-left: 24px;
}

.lookPdf {
    line-height: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.pdf-name {
    margin-left: 10px;
}

.operation {
    margin-left: 40px;
}

.Tag {
    padding: 25px 0;
}

.fenceOperation {
    display: flex;
    align-items: center;
    padding-bottom: 70px;
}

.fenceOperation .Operation-item {
    margin-right: 28px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.fenceOperation .Operation-item span {
    margin-left: 5px;
}

.videos {
    position: relative;
}

.videos .play-shade {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.play-icon {
    width: 70px;
    height: 70px;
    background: url("~@/assets/PC/fenceDetail/player.png") no-repeat;
    background-size: cover;
}

.praise img {
    width: 17px;
    height: 17px;
}

.collect img {
    width: 18px;
    height: 21px;
}

.praise {
    position: relative;

    img {
        transition: all 0.5s;
    }

    img:first-of-type {
        opacity: 1;
    }

    img:nth-of-type(2) {
        opacity: 0;
        position: absolute;
        left: 0;
    }

    &.praiseActive {
        img:first-of-type {
            opacity: 0;
        }

        img:nth-of-type(2) {
            opacity: 1;
        }
    }
}

.collect {
    position: relative;

    img {
        transition: all 0.5s;
    }

    img:first-of-type {
        opacity: 1;
    }

    img:nth-of-type(2) {
        opacity: 0;
        position: absolute;
        left: 0;
    }

    &.collectActive {
        img:first-of-type {
            opacity: 0;
        }

        img:nth-of-type(2) {
            opacity: 1;
        }
    }
}

.share {
    position: relative;
}

.share img {
    width: 20px;
    height: 20px;
}

.shareCode {
    width: 156px;
    height: 260px;
    background: #fff;
    border: 1px #ccc solid;
    position: absolute;
    top: -275px;
    left: -60px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px #999;
    display: none;
    z-index: 3;
}

.share:hover .shareCode {
    display: block;
}

.shareCode p {
    text-align: center;
    line-height: 2.5;
    display: flex;
    align-items: center;
}

.shareCode p img {
    margin-right: 5px;
}

.shareCode p:hover {
    color: #6b18a8;
    font-weight: bold;
}

.shareCode #qrCode {
    width: 110px;
    height: 110px;
    background: #ccc;
    margin: 0 auto;
    margin-top: 10px;
}

.san0 {
    position: absolute;
    width: 0;
    height: 0;
    border: 7.5px solid transparent;
    border-top-color: #fff;
    left: 68px;
    bottom: -15px;
}

.content-item {
    font-size: 18px !important;
    margin-top: 0 !important;
    margin-bottom: 10px !important;
}

iframe {
    width: 100%;
    min-height: 491px;
    max-height: 691px;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.LimitAlert {
    .shade-section {
        width: 450px;
        background: url("~@/assets/PC/InnovationContest/invite-friends.png") no-repeat;
        background-size: 100% 100%;
        padding: 110px 20px 40px 20px;
        position: relative;
        min-height: 300px;
    }

    .shade-section-title {
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 15px;
        font-family: "黑粗";
        font-weight: bold;
    }

    .shade-section-btn {
        margin-top: 50px;
    }

    .xy-btn {
        padding: 12px 40px;
        font-size: 14px;
        background: #6b18a8;
        color: #fff;
        display: inline-block;
        border: 0;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s;
    }

    .xy-btn+.xy-btn {
        margin-left: 10px;
    }

    .xy-btn:hover {
        opacity: 0.8;
    }
}

// ?移动端
@media (max-width: 750px) {
    .fence {
        .shareCode {
            height: vw(190);
            top: vw(-200);
        }

        .fence-detail {
            padding-top: vw(30);
        }
    }

    .fence-nav,
    .crumb {
        display: none;
    }

    .fence-box {
        width: auto;
        padding: 0 vw(30) vw(0);
    }

    .fence-video {
        padding: 0 0 vw(39) 0;
    }

    .fence-detail-text h1 {
        font-size: vw(32);
    }

    .fence-date {
        font-size: vw(27);
        color: #738294;
    }

    .play-num {
        margin-left: vw(40);
    }

    .lookPdf {
        line-height: vw(50);
    }

    .pdf0 img {
        width: vw(30);
        height: vw(36);
    }

    .pdf0 .pdf-name {
        min-width: 100%;
    }

    .operation {
        font-size: vw(24);
        min-width: 100%;
        margin-left: 0;
    }

    .fenceOperation {
        padding-bottom: vw(30);
    }

    .praise span,
    .collect span,
    .share span {
        font-size: vw(28);
    }

    .play-icon {
        width: vw(100);
        height: vw(100);
    }

    .content-item {
        font-size: vw(30) !important;
        margin-bottom: vw(20) !important;
    }

    iframe {
        width: 100%;
        min-height: vw(340);
        max-height: vw(500);
        height: 100%;
        margin-top: vw(20);
        margin-bottom: vw(15);
    }

    .LimitAlert {
        .shade-section {
            width: calc(100% - 20px);
            background: url("~@/assets/PC/InnovationContest/invite-friends.png") no-repeat;
            background-size: 100% 100%;
            padding: 90px 10px 40px 10px;
            position: relative;
            min-height: vw(300);
        }

        .shade-section-title {
            font-size: vw(40);
            line-height: 1.5;
            margin-bottom: 15px;
            font-family: "黑粗";
            font-weight: bold;
        }

        .shade-section-btn {
            margin-top: 20px;
        }

        .xy-btn {
            padding: 9px 15px;
            font-size: 12px;
            background: #6b18a8;
            color: #fff;
            display: inline-block;
            border: 0;
            border-radius: 3px;
        }

        .xy-btn+.xy-btn {
            margin-left: 5px;
        }
    }
}
</style>
<style>
.fence-nav .el-submenu .el-menu-item {
    min-width: 140px;
}

.fence-detail-text-item0 img {
    max-width: 100%;
    height: auto !important;
    margin: 10px 0;
}

.fence-detail-text-item0 table {
    border-collapse: collapse;
}

.fence-detail-text-item0 td,
.fence-detail-text-item0 th {
    padding: 5px 10px;
    border: 1px solid #ddd;
}
</style>

<style lang="scss">
$mobil_base: 750;

@function vw($true) {
    @return ($true / $mobil_base) * 100vw;
}

@media (max-width: 750px) {
    .vjs_video_3-dimensions {
        max-height: vw(380);
    }

    .fence-detail-text-item0 * {
        line-height: 1.7;
        font-size: vw(24);
    }
}

@media (min-width: 750px) {
    .fence-detail-text-item0 * {
        line-height: 24px;
    }
}

.video-js .vjs-big-play-button {
    width: 72px;
    height: 72px;
    border-radius: 0;
    z-index: 100;
    border: 0;
    background-color: initial !important;
    background: url("~@/assets/PC/fenceDetail/player.png") no-repeat;
    background-size: cover;
    display: none !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder {
    display: none;
}

.fence .el-loading-mask {
    position: absolute !important;
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
}

.fence .el-loading-spinner {
    height: 100%;
}

.vjs-time-tooltip {
    white-space: nowrap;
    width: 5em !important;
}
</style>